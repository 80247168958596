import React, { useEffect } from 'react';
import './Snackbar.css';

function Snackbar({ message = "message", setShowSnackbar, showSnackbar, time = 5000, error = false, }) {
  useEffect(() => {
    let timeout;
    if (showSnackbar) {
      timeout = setTimeout(() => {
        setShowSnackbar(false);
      }, time);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [showSnackbar, setShowSnackbar, time]);

  return (
    <div className={`snackbar ${showSnackbar ? 'show' : ''}  ${error ? 'error__snackbar' : ''}`}>
      {message}
    </div>
  );
}

export default Snackbar;