import React from 'react'
import ServiceCards from '../../../Components/ServiceCards/ServiceCards'
import { useTranslation } from 'react-i18next';
import MetaTags from '../../../Components/SEO/MetaTags';

const Hotel = () => {
    const { t } = useTranslation();

    const description = `
    Receive and confirm orders effortlessly with any device - PC, Mac, tablet, or smartphone. 
    Whether it's a special occasion or a casual meal, join us at Restaurant Name for a delightful dining experience.
    `
    return (
        <>
            <MetaTags href={"hotel"} pageName={"hotel"} content={description} metaTitle={"Accept Orders Anywhere: Seamless Order Management for Your Restaurant"} />

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t('Hotel.1Heading')}

                    </h1>

                    <p className="paragraph">
                        {t('Hotel.1Detail')}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>

            <ServiceCards type={1} />

            <div className="service__container reverse__container">

                <div className="service__content">

                    <a className="simple__link paragraph">
                        {t('Hotel.2Link')}
                    </a>

                    <h1 className="heading">
                        {t('Hotel.2Heading1')}
                        <span>
                            &nbsp; {t('Hotel.2Heading2')}
                        </span>
                    </h1>

                    <p className="paragraph">
                        {t('Hotel.2Detail')}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />

            </div>

            <ServiceCards type={2} />

        </>
    )
}

export default Hotel