import React from "react";
import { Config } from "../../Constant";


function Image({baseUrl, imgUrl, imgClassName, errorImageUrl, style}) {
  const imageNewUrl =  imgUrl ? `${baseUrl}${imgUrl}` : errorImageUrl || Config?.errorImageUrl;
  
  return (
    <img
      className={imgClassName}
      src={imageNewUrl}
      alt="none"
      style={style}
      onError={(e) => {
        e.target.src = errorImageUrl || Config?.errorImageUrl;
      }}
    />
  );
}

export default Image;