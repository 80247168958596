import React from 'react'
import ServiceCards from '../../../Components/ServiceCards/ServiceCards'
import { useTranslation } from 'react-i18next'
import MetaTags from '../../../Components/SEO/MetaTags'

const Bakery = () => {
    const { t } = useTranslation()

    const description = `
    Elevate your bakery's customer experience with our own online ordering system. 
    Allow customers to order directly from your website and enjoy exclusive offers, without paying any commissions to other platforms
    `

    return (
        <>
            <MetaTags href={"bakery"} pageName={"bakery"} content={description} metaTitle={"Empower Your Bakery: Enhance Customer Experience with Our Own Online Ordering System!"} />

            <div className="service__container">
                <div className="service__content">
                    <h1 className="heading">
                        {t('Bakery.1Heading')}
                    </h1>
                    <p className="paragraph">
                        {t('Bakery.1Detail')}
                    </p>
                </div>
                <img className="service__img img__anime" src="images/bussinessType/bussinessess/bakery.png" alt="" />
            </div>
            <ServiceCards type={1} />
            <div className="service__container reverse__container">
                <div className="service__content">
                    <h1 className="heading">
                        {t('Bakery.2Heading')}
                    </h1>
                    <p className="paragraph">
                        {t('Bakery.2Detail')}
                    </p>
                    <a className="simple__link paragraph">
                        {t('Bakery.2Link')}
                    </a>
                </div>
                <img className="service__img img__anime" src="images/bussinessType/bussinessess/cart.png" alt="" />
            </div>
            <ServiceCards type={2} />
            <div className="service__container">
                <div className="service__content">
                    <a className="simple__link paragraph">
                        {t('Bakery.3Link')}
                    </a>
                    <h1 className="heading">
                        {t('Bakery.3Heading1')}
                        <span>
                            &nbsp; {t('Bakery.3Heading2')}
                        </span>
                    </h1>
                    <p className="paragraph">
                        {t('Bakery.3Detail')}
                    </p>
                </div>
                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />
            </div>
            <ServiceCards type={3} />
        </>
    )
}

export default Bakery