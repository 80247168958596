import React, { useEffect, useState } from "react";
import Image from "../../../../../Components/Image/Image";
import { Config } from "../../../../../Constant";

import './Slide.css?v=1.0';

const Slide = ({ data }) => {
  const [restaurant, setRestaurant] = useState({});
  useEffect(() => {
    setRestaurant(data?.restaurant);
  }, [data]);
  console.log(restaurant);
  return (
    <>
      <div className="customer__name">

        {/* {data.image &&  */}
        <Image
          baseUrl={Config.digitalOceanLinkReferenceManMediumImg}
          imgUrl={data?.image}
          errorImageUrl="/images/noManImage.jpg"
          style={{borderRadius: "50%"}}
        />
        {/* // } */}

        <span className="name">{data?.title}</span>
      </div>

     {restaurant?.image && 
     <Image
        baseUrl={Config.digitalOceanLinkReferenceLogoMediumImg}
        imgUrl={restaurant?.image}
        errorImageUrl="/images/noImage.png"
      />
      }

      <p className="paragraph">
        <span>“</span> {data?.quote?.en} <span>”</span>
      </p>
    </>
  );
};

export default Slide;
