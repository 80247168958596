import React from 'react'
import Hero from './Components/Hero/Hero.jsx'
import Features from './Components/Features/Features.jsx'
import Services from './Components/Services/Services.jsx'
import Reviews from './Components/Reviews/Reviews.jsx'
import Shery from "sheryjs";
import './Home.css'
import MetaTags from '../../Components/SEO/MetaTags.jsx'

const Home = () => {

    const description = `
    Discover Quik Bite for easy online food ordering. Enjoy fast takeaway delivery from a variety of restaurants.
    Order now and satisfy your cravings!
    `

    Shery.textAnimate(".text-target", {
        style: 1,
        y: 10,
        delay: 0.02,
        duration: .2,
        ease: "cubic-bezier(0.23, 1, 0.320, 1)",
        multiplier: 0.1,
    });
    Shery.textAnimate(".text-target2", {
        style: 1,
        x: 30,
        delay: 0.2,
        duration: .5,
        ease: "cubic-bezier(0.23, 1, 0.320, 1)",
        multiplier: 0.1,
    });
    return (
        <>
            <MetaTags  pageName={"Home"} content={description} metaTitle={"Quik Bite: Ultimate Online Ordering System for Restaurants & Takeaways"} />

            <Hero />

            <div className="header__img"></div>

            <Features />
            <Services />
            <Reviews />
        </>
    )
}
export default Home
