import React from 'react'
import './main.css'
import ReactRouter from "./App/ReactRouter/ReactRouter";
const App = () => {
  return (
    <>
      <ReactRouter />
    </>
  )
}

export default App