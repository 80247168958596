import React from 'react'
import './Services.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const Services = () => {
    const { t } = useTranslation()
    return (
        <>

            <div className="service__container reverse__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Services.Services1Heading1")}{" "}
                        <span className='text-target2'>
                            {t("Services.Services1Heading2")}
                        </span>{" "}
                        {t("Services.Services1Heading3")}.
                    </h1>

                    <p className="paragraph text-target">
                        {t("Services.Services1Detail")}
                    </p>

                    <Link to="/onlineordering" className="simple__link paragraph text-target">
                        {t("Services.LearnMore")}
                    </Link>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Services.Services2Heading1")}{" "}
                        <span className='text-target2'>
                            {t("Services.Services2Heading2")}
                        </span>{" "}
                        {t("Services.Services2Heading3")}.
                    </h1>

                    <p className="paragraph text-target">
                        {t("Services.Services2Detail")}
                    </p>

                    <Link to="/onlineordering"  className="simple__link paragraph text-target">
                        {t("Services.LearnMore")}
                    </Link>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>

            <div className="service__container reverse__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Services.Services3Heading1")}{" "}
                        <span className='text-target2'>
                            {t("Services.Services3Heading2")}
                        </span>
                    </h1>

                    <p className="paragraph text-target">
                        {t("Services.Services3Detail")}
                    </p>

                    <Link to="/onlineordering"  className="simple__link paragraph text-target">
                        {t("Services.LearnMore")}
                    </Link>

                </div>

                <img className="service__img img__anime" src="images/home/admin.png" alt="" />

            </div>

        </>
    )
}

export default Services
