import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useGetReferencesQuery } from "../../../../quickBiteServices/quickBiteApi";
import { Pagination } from "swiper/modules";
import Slide from "./Slide/Slide";
import "./Review.css";
import { useTranslation } from "react-i18next";

const Reviews = () => {
  const { data: references } = useGetReferencesQuery();
  const [referencesData, setReferencesData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (references?.data?.restaurants) {
      setReferencesData(references.data.restaurants);
    }
  }, [references]);

  return (
    
    <div className="review__slider">
   { referencesData?.length>=3&&<>
      <div className="review__content">
        <h1 className="heading">
          {t("Reviews.Heading1")}
          <span>{t("Reviews.Heading2")}</span>
        </h1>
      </div>

      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
          },
          1124: {
            slidesPerView: 3,
          },
        }}
        modules={[Pagination]}
        className="mySwiper slider"
      >
        {referencesData.map((data, index) => (
          <SwiperSlide key={index} className="swiper__slide">
            <Slide data={data} />
          </SwiperSlide>
        ))}
        {/* {referencesData.map((data, index) => (
          <SwiperSlide key={index} className="swiper__slide">
            <Slide data={data} />
          </SwiperSlide>
        ))} */}
      </Swiper>
      </>}
    </div>
  );
};

export default Reviews;
