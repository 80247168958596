import React from 'react'
import ServiceCard from '../../OnlineOrdering/ServiceCard/ServiceCard'

const Donuts = () => {
    return (
        <>
            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        Online ordering for delivery and pickup

                    </h1>

                    <p className="paragraph">
                        Your customers can place their orders online from their smart phones instead of waiting in line at your store or they can order online for delivery.
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/douts.png" alt="" />

            </div>


            <div className="service__cards">

                <ServiceCard
                    Icon={<i class="ri-truck-line"></i>}
                    CardHeading="For Delivery"
                    CardParagraph="Receive delivery orders from your customers"
                />

                <ServiceCard
                    Icon={<i class="ri-shopping-bag-3-line"></i>}
                    CardHeading="For Pickup"
                    CardParagraph="Your customers can order and pickup when ready"
                />

                <ServiceCard
                    Icon={<i class="ri-qr-code-line"></i>}
                    CardHeading="For Dine-in"
                    CardParagraph="Customers can place orders from their tables"
                />

                {/* <ServiceCard
                    Icon={<i class="ri-phone-fill"></i>}
                    CardHeading="From Phone"
                    CardParagraph="You can create manual orders with caller-id"
                /> */}

            </div>

            <div className="service__container reverse__container">

                <div className="service__content">

                    <a className="simple__link paragraph">
                        It's so Easy to Manage
                    </a>

                    <h1 className="heading">
                        Receive and confirm orders
                        <span>
                             &nbsp;from any device
                        </span>
                    </h1>

                    <p className="paragraph">
                        You can use any tablet, notebook or smart phone to accept online food orders. No need any specific device or printer to start.
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />

            </div>

            <div className="service__cards">
                <ServiceCard
                    Icon={<i class="ri-article-line"></i>}
                    CardHeading="Cloud Print"
                    CardParagraph="Print order details easily by wireless printer"
                />

                <ServiceCard
                    Icon={<i class="ri-notification-line"></i>}
                    CardHeading="Order Notifications"
                    CardParagraph="Notify customer when preparing or on the way"
                />

                <ServiceCard
                    Icon={<i class="ri-error-warning-line"></i>}
                    CardHeading="Get Notified"
                    CardParagraph="QuickBite warns you if you have awaiting order"
                />

                {/* <ServiceCard
                    Icon={<i class="ri-phone-line"></i>}
                    CardHeading="Caller-ID"
                    CardParagraph="Create orders manually by selecting customers"
                /> */}

            </div>

        </>
    )
}

export default Donuts