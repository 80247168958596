import React from 'react'

import './Hero.css'
import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom';

const Hero = () => {

    const { t } = useTranslation()
    return (
        <div className='hero__section'>

            <div className="hero__content">

                <h1 className="heading">
                    <span className='text-target2'>
                        #1
                    </span>{" "}
                    {t("Hero.Heading")}
                </h1>

                <p className="paragraph text-target">
                    {t("Hero.Detail1")}
                </p>

                <div className="btn__container">

                    <a href="#features__container" className="btn">
                        {t("Hero.Button1")}
                    </a>

                    <Link to="/quote" className="sec__btn btn">
                        {t("Hero.Button2")}
                    </Link>

                </div>

                <p className="paragraph text-target">
                    {t("Hero.Detail2")}
                </p>

            </div>

            <img className="hero__img" src="images/home/hero.gif" alt="" />

        </div>
    )
}

export default Hero
