import React from 'react'

import './Payment.css'
import { useTranslation } from 'react-i18next';
import priceData from './data.json';
import Card from './card';
import MetaTags from '../../Components/SEO/MetaTags';

const Payment = () => {
  const { t } = useTranslation();

  const description = `
  Quik Bite offers the easiest online ordering experience. Use your own payment gateway for seamless transactions. Start ordering now!
  `

  return (
    <div className='payment__page'>
      <MetaTags href={"pricing"} pageName={"pricing"} content={description} metaTitle={"Quik Bite: Use Your Own Payment Gateway for Easy Orders"} />


      <div className="pricing__content">

        <h1 className="heading">
          {t("Pricing.Heading")}
        </h1>

        <p className="paragraph">
          {t("Pricing.note")}
        </p>

      </div>
      <div className="pricing__plan">


        <div className="plan__containers">
          {priceData.map((item) => (
            <Card item={item} />
          ))}
        </div>

      </div>

      {/* <div className="plan__detail">

       
      </div> */}

    </div>
  )
}

export default Payment
