import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import GetAQuote from '../Pages/GetAQoute/GetAQoute'
import OnlineOrdering from '../Pages/OnlineOrdering/OnlineOrdering'
import Payment from "../Pages/Payment/Payment";
import BusinessType from "../Pages/BusinessType/BusinessType";
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import Restaurant from "../Pages/BusinessType/Restaurant/Restaurant";
import Bakery from "../Pages/BusinessType/Bakery/Bakery";
import Cafe from "../Pages/BusinessType/Cafe/Cafe";
import FoodTruck from "../Pages/BusinessType/FoodTruck/FoodTruck";
import Grocery from "../Pages/BusinessType/Grocery/Grocery";
import Hotel from "../Pages/BusinessType/Hotel/Hotel";
import Beach from "../Pages/BusinessType/Beach/Beach";
import Donuts from "../Pages/BusinessType/Donuts/Donuts";
import TakeAway from "../Pages/BusinessType/TakeAway/TakeAway";
import Products from "../Pages/Products/Products";
import Gdpr from "../Components/GDPR/Gdpr";
// import TakeAway from "../Pages/BusinessType/TakeAway/TakeAway";

function ReactRouter() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/getaquote" element={<GetAQuote />} />
        <Route path="/onlineordering" element={<OnlineOrdering />} />
        <Route path="/foodtruck" element={<FoodTruck />} />
        <Route path="/pricing" element={<Payment />} />
        <Route path="/quote" element={<GetAQuote />} />
        <Route path="/businesstype" element={<BusinessType />} />
        <Route path="/restaurant" element={<Restaurant />} />
        <Route path="/bakery" element={<Bakery />} />
        <Route path="/hotel" element={<Hotel />} />
        <Route path="/cafe" element={<Cafe />} />
        <Route path="/grocery" element={<Grocery />} />
        <Route path="/beach" element={<Beach />} />
        <Route path="/donuts" element={<Donuts />} />
        <Route path="/takeAway" element={<TakeAway />} />
        <Route path="/products" element={<Products />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
      <Footer />
      <Gdpr />
    </BrowserRouter>
  );
}

export default ReactRouter;