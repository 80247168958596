import React from 'react'
import { useTranslation } from 'react-i18next';
import ServiceCards from "../../../Components/ServiceCards/ServiceCards"
import MetaTags from '../../../Components/SEO/MetaTags';

const Restaurant = () => {
    const { t } = useTranslation();

    const description = `
    Empower your restaurant with Quik Bite's online ordering system. 
    Serve customers directly from your website, without commission fees to other platforms
    `
    return (
        <>
            <MetaTags href={"restaurant"} pageName={"restaurant"} content={description} metaTitle={"Your Own Online Ordering System: Serve Customers Directly with Quik Bite"} />

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Restaurant.1Heading")}
                    </h1>

                    <p className="paragraph">
                        {t("Restaurant.1Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/restaurants.png" alt="" />

            </div>

            <ServiceCards type={1} />

            <div className="service__container reverse__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Restaurant.2Heading")}
                    </h1>

                    <p className="paragraph">
                        {t("Restaurant.2Detail")}
                    </p>

                    <a className="simple__link paragraph">
                        {t("Restaurant.2Link")}
                    </a>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/cart.png" alt="" />

            </div>

            <ServiceCards type={2} />

            <div className="service__container">

                <div className="service__content">

                    <a className="simple__link paragraph">
                        {t("Restaurant.3Link")}
                    </a>

                    <h1 className="heading">
                        {t("Restaurant.3Heading1")}
                        <span>
                            &nbsp; {t("Restaurant.3Heading2")}
                        </span>
                    </h1>

                    <p className="paragraph">
                        {t("Restaurant.3Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />

            </div>

            <ServiceCards type={3} />

        </>
    )
}

export default Restaurant