import React from 'react'

import './Payment.css'
import { useTranslation } from 'react-i18next';

const Card = ({item}) => {

    const { t } = useTranslation();

    return (
        // <div className="plan__card basic__type">
        <div className={"plan__card " + (item?.popular ? 'advanced__type' : 'basic__type')}>

            <span className="package__type">
                {item?.title}
            </span>

            <span className="price">
                <span className="currency">DKK </span>
                    {item?.price}{" "}
                <span>
                    /{t("Pricing.Month")}
                </span>
            </span>

            <div className="feature__list">

            {item?.features?.map((feature) => (
                <div className="single__feature">

                    <div className="circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                            <path d="M1.11133 6.10059L3.82738 8.66634L10.8891 1.33301" stroke="#D9D9D9" stroke-width="1.58889" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>

                    <span className="feature__text">
                        {feature?.title}
                        {/* {t("Pricing.BaskicDetail1")} */}
                    </span>

                </div>
            ))}

            </div>

        </div>
    )
}

export default Card
