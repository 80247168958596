import React from 'react'
import ServiceCards from '../../../Components/ServiceCards/ServiceCards'
import { useTranslation } from 'react-i18next';
import MetaTags from '../../../Components/SEO/MetaTags';

const Beach = () => {
    const { t } = useTranslation();

    const description = `
    Indulge in beachside flavors with our own online ordering system. Take orders effortlessly with QR codes at your beach club. 
    Enjoy the convenience of easy-to-use cloud-based management and enhanced reports for analyzing online orders."
    `
    // "Beach": {
    //     "1Heading": "Take orders with QR code at your beach club",
    //     "1Detail": "Offer your guests ease of ordering and paying from their smart phones. Place QR codes to sunbeds for taking online orders at your beach club.",
    //     "2Heading1": "Indulge in ",
    //     "2Heading2": "beachside",
    //     "2Heading3": "flavors with our own online ordering system",
    //     "2Detail": "Easy to use cloud based management dashboard and enhanced reports for analyzing your beach clubs online orders.",
    //     "2Link": "Learn About Dashboard",
    //     "3Heading1": "Receive and confirm orders",
    //     "3Heading2": "with any device",
    //     "3Detail": "You can use any PC, Mac, tablet or notebook to accept online orders. Moreover, you can even use your own smart phone to start taking orders.",
    //     "3Link": "It's so Easy to Manage"
    //   },
    return (
        <>
            <MetaTags href={"beach"} pageName={"beach"} content={description} metaTitle={"Seaside Serenity: Order and Pay with Ease at Our Beach Club!"} />

            <div className="service__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Beach.1Heading")}

                    </h1>

                    <p className="paragraph">
                        {t("Beach.1Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/qr.png" alt="" />

            </div>

            <ServiceCards type={1} />

            <div className="service__container reverse__container">

                <div className="service__content">

                    <h1 className="heading">
                        {t("Beach.2Heading1")}<span>{t("Beach.2Heading2")}</span>{t("Beach.2Heading3")}
                    </h1>

                    <p className="paragraph">
                        {t("Beach.2Detail")}
                    </p>

                    <a className="simple__link paragraph">
                        {t("Beach.2Link")}
                    </a>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/bussinessess/cart.png" alt="" />

            </div>


            <ServiceCards type={2} />

            <div className="service__container">

                <div className="service__content">

                    <a className="simple__link paragraph">
                        {t("Beach.3Link")}
                    </a>

                    <h1 className="heading">
                        {t("Beach.3Heading1")}
                        <span>
                            &nbsp; {t("Beach.3Heading2")}
                        </span>
                    </h1>

                    <p className="paragraph">
                        {t("Beach.3Detail")}
                    </p>

                </div>

                <img className="service__img img__anime" src="images/bussinessType/admin.png" alt="" />

            </div>

            <ServiceCards type={3} />

        </>
    )
}

export default Beach