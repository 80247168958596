export const Config = {
    baseUrl: 'https://danzeetech.com/quickbite-be/public/api/',
    getReferences: 'references',
    getProducts: 'products',
    getRestaurantsDetail: 'restaurants',
    errorImageUrl: 'images/Image_not_available.png',
    digitalOceanLinkReferenceManMediumImg:
    "https://danzee.fra1.digitaloceanspaces.com/quickbite/restaurant/medium/",
    digitalOceanLinkReferenceLogoMediumImg:
    "https://danzee.fra1.digitaloceanspaces.com/quickbite/reference/medium/",
}

const PAGE_URLS = {
    BASE_URL: "https://quickbite.dk/",  
  };
  
  export default PAGE_URLS;